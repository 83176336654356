import React from "react";
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import configuration from "react-global-configuration";

const AddCardModalSec = (props) => {

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  return (
    <>
      <div className="add-new-card-sec mt-5">
        <div className="mb-5 back"
          onClick={() => props.setShowAddCard(false)}>
          <i className="fas fa-arrow-left mr-2"></i> Back
        </div>
        <h4 className="payment-modal-title">Add New Card</h4>
        <Form>
          <Form.Label>Card Number</Form.Label>
          <InputGroup className="mb-4">
            <Form.Control
              placeholder="xxxx-xxxx-xxxx-xx25"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <InputGroup.Text id="basic-addon2">
              <img src="assets/images/card.png" alt="" />
            </InputGroup.Text>
          </InputGroup>
          <Row>
            <Col md={6}>
              <Form.Label>Expiry Date</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="MM/YY"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                  <img src="assets/images/month-year.png" alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={6}>
              <Form.Label>CVC/CVV</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Text id="basic-addon2">
                  <img src="assets/images/cvv.png" alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label>Card Holders Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Card Holders Full Name"
              className="card-name-text"
            />
          </Form.Group>
          <div className="add-card-btn">
            <Button type="submit">Add Card</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddCardModalSec;
