import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import "./LiveStreaming.css";
import { Link } from "react-router-dom";

const LiveStreamingStore = (props) => {

    return (
        <>
            {props.products.length > 0 ?
                <div className="live-streaming-store-product-card">
                    <div className="live-streaming-store-product-header">
                        <h4 className="text-uppercase">{props.displayName}’S STORE</h4>
                        <Link to={`/${props.userUniqueId}`}>
                            View All
                        </Link>
                    </div>
                    <div className="live-streaming-store-product-box">
                        {props.products.map((product, i) =>
                            <div className="live-streaming-store-product-item" key={i}>
                                <div className="live-streaming-store-product-img-bg-sec">
                                    <Image
                                        className="live-streaming-store-product-img"
                                        src={product.picture}
                                    />
                                </div>
                                <div className="live-streaming-store-add-cart-link">
                                    <div className="live-streaming-store-cart-icon-sec">
                                        <Link to="#">
                                            <Image
                                                className="live-streaming-store-cart-icon"
                                                src={
                                                    window.location.origin + "/assets/images/new-home/icon/cart-icon.svg"
                                                }
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="live-streaming-store-product-info-sec">
                                    <div className="live-streaming-store-product-info-card">
                                        <div className="live-streaming-store-product-details">
                                            <h3>{product.name}</h3>
                                            <p>{product.user_product_price_formatted}</p>
                                        </div>
                                        <div className="rating-star-card">
                                            <ul className="rating-star-sec">
                                                <Media as="li">
                                                    <i className="fas fa-star"></i>
                                                </Media>
                                                <Media as="li">
                                                    <i className="fas fa-star"></i>
                                                </Media>
                                                <Media as="li">
                                                    <i className="fas fa-star"></i>
                                                </Media>
                                                <Media as="li">
                                                    <i className="fas fa-star"></i>
                                                </Media>
                                                <Media as="li">
                                                    <i className="fas fa-star"></i>
                                                </Media>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="live-streaming-stock-sec">
                                    {product.is_outofstock === 1 ?
                                        <p className="text-danger">OUT OF STOCK</p>
                                        : <p>IN STOCK</p>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default LiveStreamingStore;
