import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import "./NewChat.css";
import { Link } from "react-router-dom";

const NewChatUserMedia = (props) => {

    return (
        <>
           <div className="new-chat-user-media-sec">
                <div className="new-chat-user-media-header-sec">
                    <h4>Media Files</h4>
                </div>
                <div className="new-chat-user-media-box">
                    <div className="new-chat-user-media-card">
                        <div className="new-chat-user-media-img-sec">
                            <Image
                                className="new-chat-user-media-img"
                                src={
                                window.location.origin +
                                "/assets/images/new-chat/media-1.png"
                                }
                            />
                        </div>
                    </div>
                    <div className="new-chat-user-media-card">
                        <div className="new-chat-user-media-img-sec">
                            <Image
                                className="new-chat-user-media-img"
                                src={
                                window.location.origin +
                                "/assets/images/new-chat/media-2.png"
                                }
                            />
                        </div>
                    </div>
                    <div className="new-chat-user-media-card">
                        <div className="new-chat-user-media-img-sec">
                            <Image
                                className="new-chat-user-media-img"
                                src={
                                window.location.origin +
                                "/assets/images/new-chat/media-3.png"
                                }
                            />
                        </div>
                    </div>
                </div>
           </div>
        </>
    );
};

export default NewChatUserMedia;
