import {
  NFT_USER_PROFILE_ITEMS_LIST_START,
  NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
  NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
  LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
  FETCH_NFT_COLLECTIONS_LIST_START,
  FETCH_NFT_COLLECTIONS_LIST_SUCCESS,
  FETCH_NFT_COLLECTIONS_LIST_FAILURE,
  SAVE_NFT_COLLECTIONS_ITEMS_START,
  SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS,
  SAVE_NFT_COLLECTIONS_ITEMS_FAILURE,
} from "./ActionConstant";

export function nftUserProfileItemsListStart(data) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_START,
    data,
  };
}

export function nftUserProfileItemsListSuccess(data) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_SUCCESS,
    data,
  };
}

export function nftUserProfileItemsListFailure(error) {
  return {
    type: NFT_USER_PROFILE_ITEMS_LIST_FAILURE,
    error,
  };
}

export function loadMoreUserProfileItemsStart(data) {
    return {
      type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_START,
      data,
    };
  }
  
  export function loadMoreUserProfileItemsSuccess(data) {
    return {
      type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_SUCCESS,
      data,
    };
  }
  
  export function loadMoreUserProfileItemsFailure(error) {
    return {
      type: LOAD_MORE_USER_PROFILE_ITEMS_LIST_FAILURE,
      error,
    };
  }

  export function fetchNftCollectionsListStart(data) {
    return {
      type: FETCH_NFT_COLLECTIONS_LIST_START,
      data,
    };
  }
  export function fetchNftCollectionsListSuccess(data) {
    return {
      type: FETCH_NFT_COLLECTIONS_LIST_SUCCESS,
      data,
    };
  }
  export function fetchNftCollectionsListFailure(error) {
    return {
      type: FETCH_NFT_COLLECTIONS_LIST_FAILURE,
      error,
    };
  }

  export function saveNftCollectionsItemsStart(data) {
    return {
      type: SAVE_NFT_COLLECTIONS_ITEMS_START,
      data,
    };
  }
  
  export function saveNftCollectionsItemsSuccess(data) {
    return {
      type: SAVE_NFT_COLLECTIONS_ITEMS_SUCCESS,
      data,
    };
  }
  
  export function saveNftCollectionsItemsFailure(error) {
    return {
      type: SAVE_NFT_COLLECTIONS_ITEMS_FAILURE,
      error,
    };
  }