import { areIntervalsOverlapping } from 'date-fns';
import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Image,
} from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { translate, t } from 'react-multi-lang';
import { connect } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { useHistory } from 'react-router';
import { getSuccessNotificationMessage } from '../helper/NotificationMessage';

const PrivateKeyIndex = (props) => {
  const history = useHistory();
  const [key, setKey] = useState("");

  useEffect(() => {
    if ((Object.keys(props.registerVerify.data).length > 0 && props.registerVerify.data.data.private_passphrase) ||
      (Object.keys(props.registerInputData.data).length > 0 && props.registerInputData.data.data.private_passphrase)) {
      setKey(Object.keys(props.registerVerify.data).length > 0 ?
        props.registerVerify.data.data.private_passphrase
        : props.registerInputData.data.data.private_passphrase)
    } else {
      history.push("/");
    }
  }, []);

  const onCopy = () => {
    let notificationMessage = getSuccessNotificationMessage("Private key copied");
    props.dispatch(createNotification(notificationMessage));
  }

  return (
    <>
      <div className="verification-sec ">
        <Container className="verification-center">
          <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
            <div className="c-email">
              <div className="c-email-header">
                <h1 className="c-email-header-title">Private Key</h1>
              </div>

              <div className="">
                <div className="private-key-wrapper">
                  <p className="private-key-msg text-italic opacity-30 text-title mb-4">
                    <b>Warning:</b> Never disclose this key.
                    Anyone with your private keys can steal any assets held in your account.
                    Kindly store it in a secure location.
                  </p>

                  <div className="private-key-container">
                    <div className="d-flex justify-content-center align-items-ccenter">
                      <div className="key-sec">
                        {key}
                      </div>
                    </div>
                    <div className="private-key-copy">
                      <CopyToClipboard text={key}
                        onCopy={() => onCopy()}>
                        <Image
                          className="copy-icon"
                          src={
                            window.location.origin + "/assets/images/copy.svg"
                          }
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="verify-btn-sec">
                    <Button
                      type="submit"
                      className="verify-btn"
                      onClick={() => history.push("/home")}
                    >
                      Home
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  registerVerify: state.users.registerVerify,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(PrivateKeyIndex);